.t {
  transform: none;
}
[popup] {
  z-index: 200;
  position: fixed;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  transition: opacity 0.5s 0s;
}
[popup] .dim {
  position: fixed;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 120%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 50;
}
[popup] .holder {
  position: absolute;
  left: 0rem;
  top: 0rem;
  right: auto;
  bottom: auto;
  z-index: 51;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
}
[popup] .close {
  background-image: url('/img/common/ico-popup-close.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 2rem;
  height: 2rem;
  position: absolute;
  right: 1.25rem;
  top: 1.25rem;
  left: auto;
  bottom: auto;
  z-index: 1;
}
[popup] .panel {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
[popup].on {
  opacity: 1;
}
@media (min-width: 768px) {
  [popup] .holder {
    position: relative;
  }
}
